import { createReducer } from '@reduxjs/toolkit';
import { MenuItemsResponse } from '../../types';
import { menuItemsLoaded } from './actions';

const initialState: {
    items?: MenuItemsResponse[]
} = {};

const reducer = createReducer(initialState, builder => {
    builder.addCase(menuItemsLoaded, (state, action) => {
        state.items = action.payload;
    });
});

export default reducer;
